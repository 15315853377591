@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

/* Add your custom styles here */
.ant-menu-item-only-child {
  margin-left: auto !important;
}
